/**
 * 国民の祝日のデータ
 * LINK: https://www8.cao.go.jp/chosei/shukujitsu/gaiyou.html
 */
export const holidaysJp = [
  new Date("2021/5/3"),
  new Date("2021/5/4"),
  new Date("2021/5/5"),
  new Date("2021/7/22"),
  new Date("2021/7/23"),
  new Date("2021/8/8"),
  new Date("2021/8/9"),
  new Date("2021/9/20"),
  new Date("2021/9/23"),
  new Date("2021/11/3"),
  new Date("2021/11/23"),
  new Date("2022/1/1"),
  new Date("2022/1/10"),
  new Date("2022/2/11"),
  new Date("2022/2/23"),
  new Date("2022/3/21"),
  new Date("2022/4/29"),
  new Date("2022/5/3"),
  new Date("2022/5/4"),
  new Date("2022/5/5"),
  new Date("2022/7/18"),
  new Date("2022/8/11"),
  new Date("2022/9/19"),
  new Date("2022/9/23"),
  new Date("2022/10/10"),
  new Date("2022/11/3"),
  new Date("2022/11/23"),
  new Date("2023/1/1"),
  new Date("2023/1/2"),
  new Date("2023/1/9"),
  new Date("2023/2/11"),
  new Date("2023/2/23"),
  new Date("2023/3/21"),
  new Date("2023/4/29"),
  new Date("2023/5/3"),
  new Date("2023/5/4"),
  new Date("2023/5/5"),
  new Date("2023/7/17"),
  new Date("2023/8/11"),
  new Date("2023/9/18"),
  new Date("2023/9/23"),
  new Date("2023/10/9"),
  new Date("2023/11/3"),
  new Date("2023/11/23"),
  new Date("2024/1/1"),
  new Date("2024/1/8"),
  new Date("2024/2/11"),
  new Date("2024/2/12"),
  new Date("2024/2/23"),
  new Date("2024/3/20"),
  new Date("2024/4/29"),
  new Date("2024/5/3"),
  new Date("2024/5/4"),
  new Date("2024/5/5"),
  new Date("2024/5/6"),
  new Date("2024/7/15"),
  new Date("2024/8/11"),
  new Date("2024/8/12"),
  new Date("2024/9/16"),
  new Date("2024/9/22"),
  new Date("2024/9/23"),
  new Date("2024/10/14"),
  new Date("2024/11/3"),
  new Date("2024/11/4"),
  new Date("2024/11/23"),
  new Date("2025/1/1"),
  new Date("2025/1/13"),
  new Date("2025/2/11"),
  new Date("2025/2/23"),
  new Date("2025/2/24"),
  new Date("2025/3/20"),
  new Date("2025/4/29"),
  new Date("2025/5/3"),
  new Date("2025/5/4"),
  new Date("2025/5/5"),
  new Date("2025/5/6"),
  new Date("2025/7/21"),
  new Date("2025/8/11"),
  new Date("2025/9/15"),
  new Date("2025/9/23"),
  new Date("2025/10/13"),
  new Date("2025/11/3"),
  new Date("2025/11/23"),
  new Date("2025/11/24"),
];
